import SlideAndBounce from "@/components/animations/slide-and-bounce";
import PricingPlans from "./plan-card";
import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import { Switch } from "@/components/ui/switch";
import Lottie from "lottie-react";
import arrowUpAnimation from "@/assets/animations/arrow-up-animation.json";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getSubscripionPlans } from "@/features/plans-and-subscription/subscriptionSlice";
const options = {
  loop: true, // Set to false if you want the animation to play once
  autoplay: true, // Start the animation automatically
  animationData: arrowUpAnimation,
};

function PriceAndPlans() {
  const [yearly, setYearly] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const plans = useSelector(
    (root: RootState) =>
      root.rootReducer.subscriptionReducer.subscriptionOptions
  );
  const isSubscriptionActive = useSelector(
    (root: RootState) =>
      root.rootReducer.subscriptionReducer.isSubscriptionActive
  );

  const getPlansAndSubs = useCallback(() => {
    dispatch(getSubscripionPlans()).then((res) => {
      console.log("subscription plans result", res);
    });
  }, [dispatch]);

  useEffect(() => {
    getPlansAndSubs();
  }, [getPlansAndSubs]);

  useEffect(() => {
    console.log("plans updated", plans);
    console.log("isSubscriptionActive", isSubscriptionActive);
  }, [plans, isSubscriptionActive]);

  return (
    <>
      {!isSubscriptionActive && plans.length > 0 && (
        <section>
          <SlideAndBounce>
            <article className="flex flex-col items-center mb-20">
              <div className="flex">
                <TypographyH2 className="font-semibold !text-[40px] leading-[60px]">
                  Pricing & Plans
                </TypographyH2>
              </div>
              <div className="flex items-center gap-3">
                <TypographyP className="text-[20px] capitalize">
                  {plans[1].interval}
                </TypographyP>
                <Switch
                  checked={yearly}
                  onCheckedChange={(e) => {
                    setYearly(e);
                  }}
                  className=""
                  id="airplane-mode"
                />
                <div>
                  <TypographyP className="text-[20px] capitalize">
                    {plans[0].interval}
                  </TypographyP>
                  <div className="absolute">
                    <div className="-rotate-90 size-[50px]">
                      <Lottie {...options} />
                    </div>
                    <div className="px-2 absolute left-[-60px] rounded-full bg-white text-primary text-[14px] font-medium">
                      Save 20%
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </SlideAndBounce>
          <PricingPlans plans={yearly ? plans[0].prices : plans[1].prices} />
        </section>
      )}
    </>
  );
}

export default PriceAndPlans;
