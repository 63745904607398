// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axiosInstance from "@/api/axios";
// import { ProjectLead as Lead, Project } from "../project-listing/types";

import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Plan, SubscriptionDetails } from "./types";

// export type Marker = Lead[] | null;

const initialState = {
  access: {},
  isSubscriptionActive: false as boolean,
  subscriptionOptions: [] as Plan[],
  checkoutSession: {} as { expires_at: number; id: string; url: string },
  subscriptionDetails: null as SubscriptionDetails | null,
};

// export const getProjectLeads = createAsyncThunk(
//   "project_map/getProjectLeads",
//   async ({ params, body }: { params: object; body: object }) => {
//     console.log("get project leads", params, body);

//     try {
//       const response = await axiosInstance({
//         method: "POST",
//         url: "/odoo/api/v1/projectLeads/fetch",
//         params: params,
//         data: body,
//       });

//       console.log("response", response);

//       const data = response.data.data as {
//         // projects: Project[];
//         projectLeads: Lead[];
//         lead_ids: number[];
//       };

//       // leads = leads.map((l) => {
//       //   return { ...l, image_url: getImage() };
//       // });

//       console.log("leads data", data);
//       return data;
//     } catch (err) {
//       console.log("err", err);
//     }
//   }
// );

// export const saveProjectLead = createAsyncThunk(
//   "project_map/saveProjectLead",
//   async (data: object) => {
//     // console.log("get project leads", params, data);
//     try {
//       const response = await axiosInstance({
//         method: "POST",
//         url: "/odoo/api/v1/projectLeads/save",
//         // params: params,
//         // data: data,
//         data: data,
//       });

//       console.log("response", response);

//       return response;
//     } catch (err) {
//       console.log("err", err);
//     }
//   }
// );

// export const saveFilter = async (data: object) => {
//   // console.log("get project leads", params, data);
//   try {
//     const response = await axiosInstance({
//       method: "POST",
//       url: "/odoo/api/v1/projectLeads/filters",
//       data: data,
//     });
//     console.log("response", response);
//     return response;
//   } catch (err) {
//     console.log("err", err);
//   }
// };

// export const deleteFilter = async (data: { filterId: number }) => {
//   // console.log("get project leads", params, data);
//   try {
//     const response = await axiosInstance({
//       method: "POST",
//       url: "/odoo/api/v1/projectLeads/filters/delete",
//       data: data,
//     });
//     console.log("response", response);
//     return response;
//   } catch (err) {
//     console.log("err", err);
//   }
// };

export const getSubscripionPlans = createAsyncThunk(
  "plans_and_subscription/getSubscriptionPlans",
  async () => {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: "/odoo/api/v1/subscriptions",
      });
      return response.data.data as {
        isSubscriptionActive: boolean;
        subscriptionOptions: Plan[];
      };
    } catch (err) {
      console.log("err", err);
    }
  }
);

export const createCheckoutSession = async (data: {
  priceId: string;
  successUrl: string;
}) => {
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/subscriptions/createCheckoutSession",
      data: data,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const createCustomerPortalSession = async (data: {
  returnUrl: string;
}) => {
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/subscriptions/createCustomerPortalSession",
      data: data,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

const subscriptionSlice = createSlice({
  name: "plans_and_subscription",
  initialState: initialState,
  reducers: {
    setSubscriptionOptions: (state, action) => {
      state.subscriptionOptions = action.payload;
    },
    setCheckoutSession: (state, action) => {
      state.checkoutSession = action.payload;
    },
    setSubscriptionDetails: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscripionPlans.fulfilled, (state, action) => {
      if (action.payload) {
        state.isSubscriptionActive = action.payload.isSubscriptionActive;
        state.subscriptionOptions = action.payload.subscriptionOptions;
      }
    });
  },
});

export const {
  setSubscriptionOptions,
  setCheckoutSession,
  setSubscriptionDetails,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
