import getSymbolFromCurrency from "currency-symbol-map";

const CurrencyDisplay = ({
  currencyCode = "$",
  amount,
}: {
  currencyCode?: string;
  amount?: number;
}) => {
  const symbol = getSymbolFromCurrency(currencyCode) || currencyCode;
  return (
    <span>
      {symbol} {amount && amount > 1000 ? amount / 1000 + "K" : amount}
    </span>
  );
};

export default CurrencyDisplay;
