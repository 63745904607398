"use client";
import Lottie from "lottie-react";
import aiAgentAnimation from "../../assets/animations/ai-agent.json";
const AiAgentButton = () => {
  const options = {
    loop: true, // Set to false if you want the animation to play once
    autoplay: true, // Start the animation automatically
    animationData: aiAgentAnimation,
  };

  return <Lottie {...options} />;
};
    
export default AiAgentButton;
