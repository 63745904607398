/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import aiIcon from "@/assets/ai/ai-icon.svg";
import arrowIcon from "@/assets/ai/arrow-icon.svg";
import uploadPitcureIcon from "@/assets/ai/uploadPicture-icon.svg";
import GradientButton from "../ui/gradient-button";
import { Bot, Send } from "lucide-react";
import { useSocket } from "@/contexts/SocketContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { ProjectLead } from "@/features/project-listing/types";
import ProjectCard from "@/features/project-listing/ui/ProjectCard";
import { TypingIndicator } from "../animations/typing-indicator";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import AiAgentButton from "../animations/ai-agent-button";
import ReactMarkdown from "react-markdown";

const PROMPTS = [
  {
    title: "Show me the newest permits issued",
    description:
      "Discover potential opportunities tailored to your preferences.",
  },
  {
    title: "Show me the number of projects by type",
    description: "Discover projects by type",
  },
  {
    title: "Which builder has the most new projects",
    description: "Find out builder with highest active construction projects",
  },
  {
    title: "Which builder has the highest construction value",
    description: "Find out builder with highest value construction projects",
  },
  {
    title: "Which city has the most construction activity",
    description: "Find the city with highest number of contruction projects.",
  },
];

export function DialogDemo() {
  const socket = useSocket();

  const inpRef = useRef<HTMLInputElement | null>(null);
  const [messages, setMessages] = useState<
    {
      role: "user" | "assistant";
      content: string;
      contentJSON: Partial<ProjectLead>[];
      contentType: "string" | "leadJSON";
    }[]
  >([]);
  const user = useSelector(
    (root: RootState) => root.rootReducer.userReducer.user
  );
  const [isLoading, setIsLoading] = useState(false);

  const updateMessages = useCallback(
    (body: {
      role: "user" | "assistant";
      content: string;
      contentJSON: Partial<ProjectLead>[];
      contentType: "string" | "leadJSON";
    }) => {
      setIsLoading(false);
      console.log("messages updated before 106");
      const m = [...messages];
      m.push(body);
      setMessages(m);
    },
    [messages, setMessages]
  );

  useEffect(() => {
    if (!socket) return;

    // Listen for events from the server
    socket.on("response_json", (data) => {
      console.log("Received message json:", JSON.parse(data));
      let leads: any[] = JSON.parse(data).leads;
      leads = leads.map((l) => {
        return {
          ...l,
          project_image_ids: [
            {
              id: 10,
              image_url:
                "https://acropolis-wp-content-uploads.s3.us-west-1.amazonaws.com/Building-Types-Hero.webp",
            },
          ],
        };
      });
      updateMessages({
        role: "assistant",
        content: "",
        contentJSON: leads,
        contentType: "leadJSON",
      });
    });

    socket.on("response", (data) => {
      console.log("Received message text:", data);
      updateMessages({
        role: "assistant",
        content: data,
        contentJSON: [],
        contentType: "string",
      });
    });

    // Cleanup the event listener on component unmount
    return () => {
      socket.off("message");
    };
  }, [socket, updateMessages]);

  useEffect(() => {
    console.log("messages updated 107", messages);
  }, [messages]);

  const sendMessage = (prompt?: string) => {
    const message = prompt ? prompt : inpRef.current?.value;
    if (socket && message) {
      const m = [...messages];
      m.push({
        role: "user",
        content: message,
        contentJSON: [],
        contentType: "string",
      });
      setMessages(m);
      setIsLoading(true);
      socket.emit("message", message, (res: string) => {
        console.log("message acknowledgement", res);
      });
      if (inpRef.current?.value) {
        inpRef.current.value = "";
      }
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" className="!size-[50px] !p-0">
          {/* <img className="size-[45px]" src={aiIcon} alt="ai-icon" /> */}
          <AiAgentButton />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[950px] h-[95vh] p-0 flex flex-col gap-0">
        <DialogHeader className="bg-bmpGreen-200 rounded-md rounded-b-none  text-white flex flex-row gap-2 px-4 items-center h-[64px] ">
          <img className="size-[40px]" src={aiIcon} alt="ai-icon" />
          {/* <div className="flex flex-col"> */}
          <DialogTitle className="text-[#181818] font-outfit text-[20px]">
            BuildMapper AI Agent
          </DialogTitle>

          <DialogDescription
            className="text-[12px] text-[#181818] font-outfit"
            hidden
          >
            Premium account
          </DialogDescription>
          {/* </div> */}
        </DialogHeader>
        <div className="flex flex-col h-full justify-between p-4">
          <div className="flex-1 space-y-4 md:max-h-[300px] 2xl:max-h-[500px]">
            <ScrollArea className="h-full pe-3">
              {messages.map((m, id) => (
                <div
                  key={id}
                  className={`flex gap-2 ${m.role === "user" ? "justify-end" : ""}`}
                >
                  {m.role === "assistant" && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-100">
                      <Bot className="w-5 h-5 text-emerald-600" />
                    </div>
                  )}
                  {m.contentType === "string" &&
                  typeof m.content == "string" ? (
                    <div
                      className={`rounded-lg p-3 my-1 max-w-[80%] ${
                        m.role === "user"
                          ? "bg-bmpGreen-400/70 text-primary-foreground"
                          : "bg-muted"
                      }`}
                    >
                      <ReactMarkdown>{m.content}</ReactMarkdown>
                    </div>
                  ) : (
                    <>
                      {m.contentJSON && m.contentJSON.length > 0 && (
                        <div className="w-full h-fit my-4 bg-muted">
                          <Carousel
                            opts={{
                              align: "start",
                            }}
                          >
                            <CarouselContent>
                              {m.contentJSON.map((content, ind) => (
                                <CarouselItem key={ind} className="basis-1/3">
                                  <ProjectCard
                                    className="h-[350px]"
                                    project={content}
                                  />
                                </CarouselItem>
                              ))}
                            </CarouselContent>
                            <CarouselPrevious className="absolute left-2 bg-white/80 hover:bg-white" />
                            <CarouselNext className="absolute right-2 bg-white/80 hover:bg-white" />
                          </Carousel>
                        </div>
                      )}
                    </>
                  )}
                  {m.role === "user" && (
                    <>
                      {user && user.user_avatar ? (
                        <div className="size-7 rounded-full">
                          <img
                            className="rounded-full"
                            src={`data:image/svg+xml;base64,${user.user_avatar}`}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="size-7 flex align-middle items-center justify-center rounded-full p-1 text-white uppercase !bg-charcoal">
                          {user && user.email
                            ? user.email.substring(0, 2)
                            : "-"}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
              {isLoading && (
                <div className="flex gap-2">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-100">
                    <Bot className="w-5 h-5 text-emerald-600" />
                  </div>
                  <div className="rounded-lg p-3 bg-muted">
                    <TypingIndicator />
                  </div>
                </div>
              )}
              <ScrollBar />
            </ScrollArea>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
              {messages.length === 0 && (
                <p className="self-stretch flex-grow-0 font-outfit text-[20px] font-semibold leading-[1] tracking-normal text-left text-[#181818]">
                  How can I help you ?
                </p>
              )}
              <Carousel
                opts={{
                  align: "start",
                }}
                className="w-[90%] mx-auto"
              >
                <CarouselContent>
                  {PROMPTS.map((prompt, ind) => (
                    <CarouselItem key={ind} className="relative basis-1/3">
                      <div
                        className="p-4 border rounded-md shadow-md relative cursor-pointer"
                        onClick={() => {
                          sendMessage(prompt.title);
                        }}
                      >
                        <h3 className=" h-[36px] mb-[12px] mr-[12px] font-outfit text-[15px] font-medium leading-[1.13] tracking-normal text-left text-[#181818]">
                          {prompt.title}
                        </h3>
                        <p className="h-[36px] mt-[12px] opacity-70 font-outfit text-[14px] font-normal leading-[1.29] tracking-normal text-left text-[#181818]">
                          {prompt.description}
                        </p>
                        <img
                          className="size-[20px] absolute right-2 top-2"
                          src={arrowIcon}
                          alt="arrow-icon"
                          onClick={() => {
                            sendMessage(prompt.title);
                          }}
                        />
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="absolute bg-white/80 hover:bg-white" />
                <CarouselNext className="absolute  bg-white/80 hover:bg-white" />
              </Carousel>
            </div>
            <div className="flex gap-2 items-center">
              {/* Custom Input Field */}
              <div className="relative w-full">
                <Input
                  ref={inpRef}
                  id="customInput"
                  placeholder="Ask me anything..."
                  className="mt-1 w-full h-[56px] flex items-center gap-3 px-6 py-4 border-slate-400 rounded-full"
                  onKeyDown={(e) => {
                    console.log("keydown event", e);
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <img
                  src={uploadPitcureIcon} // Replace with your actual image source
                  alt="Add Picture"
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                />
              </div>
              <GradientButton
                onClick={() => {
                  sendMessage();
                }}
                className="size-[50px] rounded-full"
              >
                <Send />
              </GradientButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
