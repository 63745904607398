import axiosInstance from "@/api/axios";
// import mockLeads from "../mockLeads";

export interface API_RESPONSE {
  id: number;
  stage_id: [number, string];
  expected_revenue: number;
  prorated_revenue: number;
  display_name: string;
  partner_name: string;
  contact_name: string;
  priority: string;
  color: number;
  activity_type_id: [number, string];
  activity_type_icon: string;
  activity_summary: string;
  activity_state: string;
  company_currency: [number, string];
  tags: {
    id: number;
    name: string;
    display_name: string;
  }[];
}

export interface Lead {
  id: number;
  stage_id: [number, string];
  expected_revenue: number;
  prorated_revenue: number;
  display_name: string;
  partner_name: string;
  contact_name: string;
  priority: string;
  color: number;
  activity_type_id: [number, string];
  activity_type_icon: string;
  activity_summary: string;
  activity_state: string;
  company_currency: [number, string];
  tags: {
    id: number;
    name: string;
    display_name: string;
  }[];
  longitude?: string;
  latitude?: string;
  description?: string;
  city?: string;
  state_id: [number, string];
  country_id: [number, string];
  address?: string;
  image_url?: string[];
  project_image_ids: { id: number; image_url: string }[];
  permit_issue_date: string;
  project_type_id: [number, string];
  project_class_id: [number, string];
  project_status_id: [number, string];
  owner_email: string;
  owner_company: string;
  owner_name: string;
  owner_phone: string | boolean;
  project_description: string;
  user_id: [number, string];
  company_id: [number, string];
  builder_id: [number, string];
  user_avatar: string;
  probability: number;
  builder: {
    id: number;
    partner_id: [number, string];
    company_id: [number, string];
    organization_id: boolean;
    name: string;
    phone: string;
    email: string;
    display_name: string;
    create_uid: [number, string];
    create_date: string;
    write_uid: [number, string];
    write_date: string;
  };
  color_hex?: string;
}

export interface Stage {
  id: number;
  name: string;
  sequence: number;
  is_won: boolean;
  requirements: boolean;
  team_id: number;
  fold: boolean;
  team_count: number;
  display_name: string;
  create_uid: [number, string];
  create_date: string;
  write_uid: [number, string];
  write_date: string;
}

export interface Column {
  id: number;
  name: string;
  sequence: number;
  is_won: boolean;
  requirements: boolean;
  team_id: number;
  fold: boolean;
  team_count: number;
  display_name: string;
  create_uid: [number, string];
  create_date: string;
  write_uid: [number, string];
  write_date: string;
  items: Lead[];
}

export type STAGE_GROUP = {
  color: number;
  expected_revenue: number;
  probability: number;
  recurring_revenue_monthly: number;
  stage_id: [number, string];
  stage_id_count: number;
};

export type PROGRESS_BAR = { today: number; overdue: number; planned: number };

export function formatPrice(amount: number): string {
  // Handle different number ranges and format accordingly
  if (amount >= 1_000_000) {
    const result = (amount / 1_000_000).toFixed(1);
    return result.endsWith(".0")
      ? `${Math.floor(amount / 1_000_000)}M `
      : `${result}M `; // For millions
  } else if (amount >= 1_000) {
    const result = (amount / 1_000).toFixed(1);
    return result.endsWith(".0")
      ? `${Math.floor(amount / 1_000)}K `
      : `${result}K `; // For thousands
  } else {
    return amount.toString(); // Return the number as is if less than 1,000
  }
}

export const getStages = async () => {
  // setIsLoading(true);
  try {
    const response = await axiosInstance({
      method: "GET",
      url: "/odoo/api/v1/crm/stages",
    });
    console.log("response", response);
    // setColumns(response.data.data.data.stages);
    return response.data.data.stages;
  } catch (err) {
    console.log("err", err);
  } finally {
    // setIsLoading(false);
  }
};
export const getStageColor = async () => {
  // setIsLoading(true);
  try {
    const response = await axiosInstance({
      method: "GET",
      url: "/odoo/api/v1/crm/leads/color",
    });
    console.log("response", response);
    // setColumns(response.data.data.data.stages);
    return response.data;
  } catch (err) {
    console.log("err", err);
  } finally {
    // setIsLoading(false);
  }
};
export const updateLeadColor = async (data: {
  leadId: number;
  colorId: number;
}) => {
  try {
    const response = await axiosInstance({
      method: "PUT",
      url: "/odoo/api/v1/crm/leads/color",
      data: data,
    });
    console.log("response", response);
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const enrichLeadService = async (data: { leadId: number }) => {
  try {
    const response = await axiosInstance({
      method: "PUT",
      url: "/odoo/api/v1/crm/leads/enrich",
      data: data,
    });
    console.log("response", response);
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const addStage = async (data: { name: string; displayName: string }) => {
  // setIsLoading(true);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/crm/stages",
      data: data,
    });
    console.log("response", response);
    // setColumns(response.data.data.data.stages);
    return response.data;
  } catch (err) {
    console.log("err", err);
  } finally {
    // setIsLoading(false);
  }
};

export const getLeads = async (params?: object) => {
  console.log("params", params);
  try {
    const response = await axiosInstance({
      method: "GET",
      url: "/odoo/api/v1/crm/leads",
      params: params,
    });

    console.log("response", response);

    return response.data.data;
  } catch (err) {
    console.log("get Leads call err", err);
  }
};

export const deleteStage = async (data: { stageId: number }) => {
  try {
    const response = await axiosInstance({
      method: "POST",
      url: `/odoo/api/v1/crm/stages/delete`,
      data: data,
    });
    console.log("response", response);
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const changeLeadStage = async (
  leadId: string | number,
  stageId: number
) => {
  try {
    const response = await axiosInstance({
      method: "PUT",
      url: `/odoo/api/v1/crm/leads/changeStage`,
      data: { leadId: Number(leadId), stageId: stageId },
    });
    console.log("response", response);
  } catch (err) {
    console.log("err", err);
  }
};

export const changeStageSequence = async (data: object) => {
  try {
    const response = await axiosInstance({
      method: "PUT",
      url: `/odoo/api/v1/crm/stages/sequence`,
      data: data,
    });
    console.log("response", response);
    return response.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const removeProjectLead = async (data: object) => {
  // console.log("get project leads", params, data);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/crm/leads/delete",
      // params: params,
      // data: data,
      data: data,
    });

    console.log("response", response);

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const addTags = async (data: object) => {
  // console.log("get project leads", params, data);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/crm/leads/tag",
      // params: params,
      // data: data,
      data: data,
    });

    console.log("response", response);

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const addNote = async (data: object) => {
  // console.log("get project leads", params, data);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/crm/leads/note",
      // params: params,
      // data: data,
      data: data,
    });

    console.log("response", response);

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

// Mock data fetch from API
export const transformToKanban = async (
  stages: Partial<Column>[],
  leads: Lead[]
): Promise<Column[]> => {
  console.log("stages", stages);
  console.log("leads", leads);

  const transformedStages = stages.map((stage) => {
    const leadsInStage = leads.filter((lead) => lead.stage_id[0] === stage.id);
    return {
      ...stage,
      items: leadsInStage.length > 0 ? leadsInStage : [],
    } as Column;
  });

  return transformedStages;
};

// Mock save API (you'd call your actual API endpoint here)
export const saveKanbanData = async (
  updatedColumns: Column[]
): Promise<void> => {
  console.log("Saving kanban data to server...", updatedColumns);
};

export const updateColumnOrder = async (columnIds: number[]) => {
  try {
    const response = await axiosInstance.put("/api/stages/reorder", {
      columnIds,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating column order:", error);
    return false;
  }
};
