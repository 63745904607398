import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/moving-border";
import { FiBarChart2, FiFileText, FiMail } from "react-icons/fi";
import { Price } from "@/features/plans-and-subscription/types";

const AllInOnePack: React.FC<{ plan: Price }> = ({ plan }) => {
  return (
    <Card className="text-start flex flex-col md:flex-row w-full p-2 bg-white border rounded-lg shadow-md">
      {/* Pricing Section */}
      <div className="md:w-1/2">
        <CardHeader>
          <CardTitle className="text-primary text-[18px] font-semibold">
            {plan.title}
          </CardTitle>
          <div className="text-[14px] !font-medium text-charcoal">
            {plan.description}
          </div>
          <CardDescription className="text-[24px] font-bold text-charcoal">
            ${plan.price}
            <span className="text-lg font-normal"> / {plan.billingCycle}</span>
          </CardDescription>
          {/* <p className="text-sm text-gray-500">billed annually</p> */}
        </CardHeader>
        {/* <CardContent>
          <p className="text-gray-600 mb-4"></p>
        </CardContent> */}
        <CardFooter>
          <Button
            borderRadius="1.75rem"
            className=" bg-bmpGreen-100 text-primary text-[17px] font-semibold dark:text-white border-neutral-200"
            containerClassName="w-[60%]"
          >
            Start Subscription
          </Button>
        </CardFooter>
      </div>

      {/* Features Section */}
      <div className="md:w-1/2 p-4 flex flex-col ps-28 gap-6 items-start justify-center">
        <div className="flex items-center">
          <FiBarChart2 className="text-green-600 text-2xl mr-4" />
          <p className="font-semibold text-lg">SALES PRO</p>
        </div>
        <div className="flex items-center">
          <FiFileText className="text-green-600 text-2xl mr-4" />
          <p className="font-semibold text-lg">DOCUMENT SUITE</p>
        </div>
        <div className="flex items-center">
          <FiMail className="text-green-600 text-2xl mr-4" />
          <p className="font-semibold text-lg">MARKETING HUB</p>
        </div>
      </div>
    </Card>
  );
};

export default AllInOnePack;
