import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import userReducer from "@/features/auth/UserSlice";
import kanbanReducer from "@/features/kanban/kanbanSlice";
import questionnaireReducer from "@/features/questionnaire/QuestionSlice";
import mapViewReducer from "@/features/map-view/mapSlice";
import subscriptionReducer from "@/features/plans-and-subscription/subscriptionSlice";

const rootReducer = combineReducers({
  userReducer: userReducer,
  kanbanReducer: kanbanReducer,
  questionnaireReducer: questionnaireReducer,
  mapViewReducer: mapViewReducer,
  subscriptionReducer: subscriptionReducer,
});

export const store = configureStore({
  reducer: {
    rootReducer: rootReducer,
  },
});

// Infer the type of `st ore`
export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
// Infer the `AppDispatch` type from the store itself
export type  AppDispatch = AppStore["dispatch"];
export type AppSelect = typeof store.getState;
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
