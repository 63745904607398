"use strict";

import logo from "@/assets/logo.svg";
import exploreIcon from "@/assets/icons/explore.svg";
import { NavLink } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { LOCALIZATION_KEYS } from "@/i18n/keys";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import canadaFlag from "@/assets/countries/canada-logo.svg";
import americaFlag from "@/assets/countries/united-states-flag.svg";
import SignUpDialog from "@/components/dialogs/SignUpDialog";
import QuestionnaireDialog from "../dialogs/QuestionnaireDialog";
import LoginDialog from "../dialogs/LoginDialog";
import { useCallback, useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import {
  setUser,
  toggleQuestion,
  changeCountry,
  logoutUser,
  toggleSignUpDialog,
} from "@/features/auth/UserSlice";
import { useLoading } from "@/contexts/LoadingContext";
import { User } from "@/features/auth/types";
import {
  createCustomerPortalSession,
  setSubscriptionDetails,
} from "@/features/plans-and-subscription/subscriptionSlice";
import DialogSubscriptionPayment from "../dialogs/dialog-subscription-payment";

export function Header({ className }: { className?: string }) {
  const questionDone = useSelector(
    (state: RootState) => state.rootReducer.userReducer.questionDone
  );
  const user = useSelector(
    (state: RootState) => state.rootReducer.userReducer.user
  );
  const subscriptionDetails = useSelector(
    (root: RootState) =>
      root.rootReducer.subscriptionReducer.subscriptionDetails
  );
  const dispatch = useDispatch<AppDispatch>();

  const openSignUpDialog = () => {
    dispatch(toggleSignUpDialog(true));
  };

  const [selectedCountry, setSelectedCountry] = useState<"CA" | "US">("CA");
  const [userName, setUserName] = useState("");
  const { setLoading } = useLoading();

  useEffect(() => {
    dispatch(changeCountry(selectedCountry));
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    console.log("user", user);
    if (user) {
      const name = user.email?.split("@")[0];
      setUserName(name);
    } else {
      const localUser = JSON.parse(localStorage.getItem("user") + "");
      if (localUser) {
        console.log("local user", localUser);
        dispatch(setUser(localUser));
        if (localUser.subscription_details) {
          dispatch(setSubscriptionDetails(localUser.subscription_details));
        }
        const questionDone = localStorage.getItem("questionDone");
        if (questionDone) {
          dispatch(toggleQuestion(questionDone == "true"));
        }
      }
    }
  }, [user, dispatch]);

  console.log("userName", userName);

  const logout = () => {
    try {
      setLoading(true);
      dispatch(logoutUser()).then(() => {
        window.location.reload();
      });
    } finally {
      setLoading(false);
    }
  };

  const setUpCustomerPortalSession = useCallback(async () => {
    setLoading(true);
    const res = await createCustomerPortalSession({
      returnUrl: "https://buildmapper.ai",
    });
    setLoading(false);
    if (res.statusCode == 200) {
      const session = res.data.customerPortalSession;
      if (session) {
        window.open(session.url, "_self");
      }
    }
  }, [setLoading]);

  return (
    <header
      className={`sticky top-0 z-50 w-[100vw] bg-white flex justify-around items-center h-[60px] drop-shadow-lg ${className}`}
    >
      <figure>
        <NavLink to="/">
          <img src={logo} alt="company-logo" className="h-[40px]" />
        </NavLink>
        {/* <OptimizedImage src={logo} alt="company-logo" className="h-[40px]" /> */}
      </figure>
      <NavMenu user={user} openSignUpDialog={openSignUpDialog} />
      <div className="flex items-center gap-2">
        <NavLink to="/sign-up" aria-label="Login">
          <Select
            value={selectedCountry}
            onValueChange={(e) => {
              setSelectedCountry(e as typeof selectedCountry);
            }}
          >
            <SelectTrigger className="w-[80px] text-charcoal">
              <SelectValue placeholder="Country" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="CA">
                <img
                  src={canadaFlag}
                  alt="canada-flag"
                  width="32px"
                  height="32px"
                />
              </SelectItem>
              <SelectItem value="US">
                <img
                  src={americaFlag}
                  alt="usa-flag"
                  width="32px"
                  height="32px"
                />
              </SelectItem>
            </SelectContent>
          </Select>
        </NavLink>
        {/* <NavLink to="/sign-up" aria-label="Login">
          <Button variant="outline" className="text-charcoal ">
            {t(LOCALIZATION_KEYS.NAV_LINKS.LOGIN)}
          </Button>
        </NavLink> */}

        {user ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <div className="flex items-center gap-2">
                  {user.user_avatar ? (
                    <div className="size-7 rounded-full">
                      <img
                        className="rounded-full"
                        src={`data:image/svg+xml;base64,${user.user_avatar}`}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="size-7 flex align-middle items-center justify-center rounded-full p-1 text-white uppercase !bg-charcoal">
                      {user.email ? user.email.substring(0, 2) : "-"}
                    </div>
                  )}
                  <span>{userName ? userName : "John Doe"}</span>
                  <GiHamburgerMenu className="text-[20px]" />
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-52">
              <DropdownMenuLabel className="text-charcoal cursor-pointer text-[16px]">
                My Account
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="text-charcoal cursor-pointer text-[16px]">
                {/* onClick={() => {
                    dispatch(togglePaymentStatusDialog(true));
                  }} */}
                Profile
              </DropdownMenuItem>
              <DropdownMenuItem className="text-charcoal cursor-pointer text-[16px]">
                My Leads
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={logout}
                className="text-charcoal cursor-pointer text-[16px]"
              >
                Log out
              </DropdownMenuItem>
              {subscriptionDetails && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel className="text-charcoal cursor-pointer text-[16px]">
                    Active Subscription
                  </DropdownMenuLabel>
                  {/* <DropdownMenuSeparator /> */}
                  <DropdownMenuItem
                    onClick={setUpCustomerPortalSession}
                    className="text-charcoal cursor-pointer text-[16px] flex justify-between"
                  >
                    <span className="text-primary">
                      {subscriptionDetails.odoo_subscription_name}
                    </span>
                    <span className="font-semibold">
                      ${" "}
                      {subscriptionDetails.amount > 1000
                        ? subscriptionDetails.amount / 1000 + "K"
                        : subscriptionDetails.amount}
                    </span>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <>
            <LoginDialog />

            {/* sign up dialog */}
            <SignUpDialog />
          </>
        )}
        {!questionDone && <QuestionnaireDialog />}
        <DialogSubscriptionPayment />
      </div>
    </header>
  );
}

const NavMenu = ({
  user,
  openSignUpDialog,
}: {
  user: User | null;
  openSignUpDialog: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <nav className="flex items-center">
      <NavLink
        className="!no-underline"
        to={user ? "/dashboard/explore_leads" : ""}
        aria-label="Explore projects"
      >
        <Button
          onClick={openSignUpDialog}
          className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200"
        >
          <img
            src={exploreIcon}
            alt="explore-icon"
            width="20px"
            height="20px"
            className="me-1"
          />
          {t(LOCALIZATION_KEYS.NAV_LINKS.EXPLORE)}
        </Button>
      </NavLink>
      <NavLink
        className="!no-underline"
        to={user ? "/dashboard/CRM" : ""}
        aria-label="How it works"
      >
        <Button
          onClick={openSignUpDialog}
          className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 "
        >
          CRM
        </Button>
      </NavLink>
      <NavLink className="!no-underline" to="/" aria-label="Pricing">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 ">
          {t(LOCALIZATION_KEYS.NAV_LINKS.PRICING)}
        </Button>
      </NavLink>
      <NavLink className="!no-underline" to="/blogs" aria-label="Blog">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 ">
          Blog
        </Button>
      </NavLink>
    </nav>
  );
};
