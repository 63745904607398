import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import PaymentSuccess from "@/pages/payment-success";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { togglePaymentStatusDialog } from "@/features/auth/UserSlice";

const DialogSubscriptionPayment: React.FC = () => {
  const [open, setOpen] = useState(false);
  const paymentStatusDialog = useSelector(
    (root: RootState) => root.rootReducer.userReducer.paymentStatusDialog
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    console.log("payment status dialog state change", paymentStatusDialog);
    setOpen(paymentStatusDialog);
  }, [paymentStatusDialog]);

  const toggleDialog = (val: boolean) => {
    dispatch(togglePaymentStatusDialog(val));
  };

  return (
    <Dialog open={open} onOpenChange={toggleDialog}>
      {/* Dialog Content */}
      <DialogContent
        aria-description="Sign up to BMP"
        aria-describedby="sign-up-dialog-description"
        className="max-w-[60vw] max-h-[90vh] p-0"
      >
        <DialogHeader className="" hidden>
          <DialogTitle
            hidden
            className="text-center font-sans mt-[-10px] text-[18px] font-semibold leading-[20px] text-black"
          >
            payment status dialog
          </DialogTitle>
          <DialogDescription hidden>
            this dialog subscription is being processed and it's different
            states.
          </DialogDescription>
        </DialogHeader>

        {/* SignUp form inside the dialog */}
        <section className="p-2 flex justify-end gap-2">
          <PaymentSuccess />
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSubscriptionPayment;
