import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

// Create the context
const SocketContext = createContext<Socket | null>(null);

const socketURL = "wss://api.buildmapper.ai"; // Replace with your server URL

// SocketProvider component to wrap the application
export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const socketInstance = io(socketURL, {
      transports: ["websocket"], // Ensure using WebSocket for better performance
    });

    socketInstance.on("connect", () => {
      console.log("Connection established");
    });

    // Store the socket instance when connected
    setSocket(socketInstance);

    // Clean up the socket connection on unmount
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

// Custom hook to use socket
export const useSocket = (): Socket | null => {
  const socket = useContext(SocketContext);
  if (!socket) {
    // throw new Error("Socket not initialized!");
    console.log('socket connection failed!');
  }
  return socket;
};
