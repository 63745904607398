import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "../App";
import Home from "@/pages/home/home";
import CRMLayout from "@/features/crm/layout";

import { Suspense } from "react";
import {
  BlogDetails,
  BlogsListing,
  KanbanBoard,
  LeadDetails,
  Listing,
  ComingSoon,
  Calendar,
  Contacts,
  AllApps,
  Quotes,
  Contracts,
  Invoicing,
  MarketingAutomation,
  KnowledgeBase,
  DiscussLayout,
  Inbox,
  DirectMessage,
  Helpdesk,
  AddLead,
  ListView,
  ActivityView,
  PaymentSuccess,
  Integration,
} from "./lazyImports";
import Loading from "./loading";

// import ProtectedRoute from "./protected-route";

{
  /* fallback={
    <div className="loading-overlay">
      <div className="spinner z-50">
        <GridLoader color="#28af4a" />
      </div>
    </div>
  } */
}

// const isAuth = Boolean(localStorage.getItem("accessToken"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="dashboard" element={<CRMLayout />}>
        <Route
          index
          path="CRM"
          element={
            <Suspense fallback={<Loading />}>
              <KanbanBoard />
            </Suspense>
          }
        />
        <Route
          path="leads/:id"
          element={
            <Suspense fallback={<Loading />}>
              <LeadDetails />
            </Suspense>
          }
        />
        <Route
          path="CRM/add_lead"
          element={
            <Suspense fallback={<Loading />}>
              <AddLead />
            </Suspense>
          }
        />
        <Route
          path="explore_leads"
          element={
            <Suspense fallback={<Loading />}>
              <Listing />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <ComingSoon />
            </Suspense>
          }
        />
        <Route
          path="calendar"
          element={
            <Suspense fallback={<Loading />}>
              <Calendar />
            </Suspense>
          }
        />
        <Route
          path="CRM/list_view"
          element={
            <Suspense fallback={<Loading />}>
              <ListView />
            </Suspense>
          }
        />
        <Route
          path="CRM/activity_view"
          element={
            <Suspense fallback={<Loading />}>
              <ActivityView />
            </Suspense>
          }
        />
        <Route
          path="all_apps"
          element={
            <Suspense fallback={<Loading />}>
              <AllApps />
            </Suspense>
          }
        />
        <Route
          path="integration"
          element={
            <Suspense fallback={<Loading />}>
              <Integration />
            </Suspense>
          }
        />
        <Route
          path="contacts"
          element={
            <Suspense fallback={<Loading />}>
              <Contacts />
            </Suspense>
          }
        />
        <Route
          path="quotes"
          element={
            <Suspense fallback={<Loading />}>
              <Quotes />
            </Suspense>
          }
        />
        <Route
          path="contracts"
          element={
            <Suspense fallback={<Loading />}>
              <Contracts />
            </Suspense>
          }
        />
        <Route
          path="invoicing"
          element={
            <Suspense fallback={<Loading />}>
              <Invoicing />
            </Suspense>
          }
        />
        <Route
          path="automation"
          element={
            <Suspense fallback={<Loading />}>
              <MarketingAutomation />
            </Suspense>
          }
        />
        <Route
          path="knowledge_base"
          element={
            <Suspense fallback={<Loading />}>
              <KnowledgeBase />
            </Suspense>
          }
        />
        <Route
          path="discuss"
          element={
            <Suspense fallback={<Loading />}>
              <DiscussLayout />
            </Suspense>
          }
        >
          <Route
            index
            path="inbox"
            element={
              <Suspense fallback={<Loading />}>
                <Inbox />
              </Suspense>
            }
          />
          <Route
            index
            path="message"
            element={
              <Suspense fallback={<Loading />}>
                <DirectMessage />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="helpdesk"
          element={
            <Suspense fallback={<Loading />}>
              <Helpdesk />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="blogs"
        element={
          <Suspense fallback={<Loading />}>
            <BlogsListing />
          </Suspense>
        }
      />
      <Route
        path="blogs/:id"
        element={
          <Suspense fallback={<Loading />}>
            <BlogDetails />
          </Suspense>
        }
      />
      <Route
        path="payment-success/:id"
        element={
          <Suspense fallback={<Loading />}>
            <PaymentSuccess />
          </Suspense>
        }
      />
    </Route>
  )
);

export default router;
