/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import { useEffect, useState } from "react";
import { Check } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import { reLoginUser } from "@/features/auth/UserSlice";

export default function PaymentSuccess() {
  const [showCheck, setShowCheck] = useState(false);
  const [subscriptionActivated, setSubscriptionActivated] = useState(false);
  const [completeAccountSetup, setCompleteAccountSetup] = useState(false);

  const notification = useSelector(
    (root: RootState) => root.rootReducer.userReducer.notification
  );

  const { id } = useParams(); // Get blog SEO title from URL params
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
    // Trigger the animation after component mount
    const timer = setTimeout(() => setShowCheck(true), 100);
    return () => clearTimeout(timer);
  }, [id, navigate]);

  useEffect(() => {
    console.log("notification recieved in payment success", notification);
    const notif = notification as any;
    if (notification) {
      if (notif.title == "ReLogin") {
        console.log("subscription activated", notif.title);
        setSubscriptionActivated(true);
      }
    }
  }, [notification]);

  useEffect(() => {
    if (subscriptionActivated) {
      dispatch(
        reLoginUser({
          platform: 3,
        })
      ).then(() => {
        setCompleteAccountSetup(true);
      });
    }
  }, [subscriptionActivated, dispatch]);

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-white to-gray-100 p-4">
      <Card className="w-full max-w-md">
        <CardHeader className="text-center">
          <div className="mb-4 flex justify-center">
            <div className="relative h-24 w-24 flex items-center justify-center">
              {/* Success circle animation */}
              <div
                className={`absolute inset-0 rounded-full border-4 border-primary transition-all duration-700 ease-out ${
                  showCheck ? "scale-100 opacity-100" : "scale-50 opacity-0"
                }`}
              />
              {/* Checkmark animation */}
              <Check
                className={`h-12 w-12 text-primary transition-all duration-700 ease-out ${
                  showCheck ? "scale-100 opacity-100" : "scale-50 opacity-0"
                } delay-300`}
              />
            </div>
          </div>
          <CardTitle
            className={`text-2xl transition-all duration-700 ${
              showCheck
                ? "translate-y-0 opacity-100"
                : "translate-y-4 opacity-0"
            } delay-500`}
          >
            Payment Successful!
          </CardTitle>
          <CardDescription
            className={`transition-all duration-700 ${
              showCheck
                ? "translate-y-0 opacity-100"
                : "translate-y-4 opacity-0"
            } delay-700`}
          >
            We're processing your subscription request
          </CardDescription>
        </CardHeader>
        <CardContent
          className={`space-y-6 text-center transition-all duration-700 ${
            showCheck ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"
          } delay-1000`}
        >
          {/* Status Steps */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Payment confirmed</span>
              <Check className="h-5 w-5 text-green-500" />
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-muted-foreground">
                Setting up your account
              </span>
              {completeAccountSetup ? (
                <Check className="h-5 w-5 text-green-500" />
              ) : (
                <div className="h-2 w-2 rounded-full bg-primary animate-[pulse_1s_ease-in-out_infinite_200ms]" />
              )}
            </div>
            <div className="flex items-center justify-between opacity-70">
              <span className="text-sm text-muted-foreground">
                Activating subscription
              </span>
              {subscriptionActivated ? (
                <Check className="h-5 w-5 text-green-500" />
              ) : (
                <div className="h-2 w-2 rounded-full bg-primary animate-[pulse_1s_ease-in-out_infinite_200ms]" />
              )}
            </div>
          </div>

          {/* Additional Information */}
          <div className="rounded-lg bg-muted p-4">
            <p className="text-sm text-muted-foreground">
              You'll receive a confirmation email once your subscription is
              activated. This usually takes less than 5 minutes.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
