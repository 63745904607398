import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { TypographyH3 } from "@/components/typography/TypographyH3";
import { TypographyP } from "@/components/typography/TypographyP";
import AllInOnePack from "./all-in-one-pack";
import { createCheckoutSession } from "@/features/plans-and-subscription/subscriptionSlice";
import { useLoading } from "@/contexts/LoadingContext";
import { Price } from "@/features/plans-and-subscription/types";
import { Button } from "@/components/ui/moving-border";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { toggleSignUpDialog } from "@/features/auth/UserSlice";

const PricingPlans: React.FC<{ plans: Array<Price> }> = ({ plans }) => {
  // const dispatch = useDispatch<AppDispatch>();
  const { setLoading } = useLoading();

  const user = useSelector(
    (root: RootState) => root.rootReducer.userReducer.user
  );

  const dispatch = useDispatch<AppDispatch>();

  const setUpCheckoutSession = useCallback(
    async (priceID: string) => {
      if (!user) {
        dispatch(toggleSignUpDialog(true));
        return;
      }

      setLoading(true);
      const res = await createCheckoutSession({
        priceId: priceID,
        successUrl: `https://buildmapper.ai/payment-success/${user._id}`,
      });
      setLoading(false);
      console.log("create checkout session response", res);
      if (res.statusCode == 200) {
        const session = res.data.checkoutSession;
        // localStorage.clear();
        // sessionStorage.clear();
        window.open(session.url, "_self");
      }
    },
    [setLoading, dispatch, user]
  );

  const [gridPlans, setGridPlans] = useState<Array<Price>>([]);
  const [panelPlans, setPanePlans] = useState<Array<Price>>([]);

  useEffect(() => {
    const grids = plans.filter((p) => p.metadata.view == "grid");
    const panes = plans.filter((p) => p.metadata.view == "pane");

    setGridPlans(grids);
    setPanePlans(panes);
  }, [plans]);

  return (
    <section className="flex flex-col gap-2">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {gridPlans.map((plan) => (
          <Card
            key={plan.title}
            className="w-full max-w-md border rounded-lg shadow-lg"
          >
            <CardHeader className="text-start">
              <CardTitle className="text-green-600">{plan.title}</CardTitle>

              <div className="text-charcoal text-[14px] mt-3 font-normal leading-[22px] h-[80px]">
                {plan.description}
              </div>

              <CardDescription className="text-3xl font-bold text-charcoal">
                ${plan.price}
                <span className="text-base font-normal">
                  {" "}
                  / {plan.billingCycle}
                </span>
              </CardDescription>
              {/* {!yearly && (
                <p className="text-sm text-gray-500">billed annually</p>
              )} */}
            </CardHeader>
            <CardContent className="h-[250px]">
              {plan.features.map((feature) => (
                <div key={feature.name} className="mb-4 text-start">
                  <TypographyH3 className="font-semibold !text-[17px] ">
                    {feature.name}
                  </TypographyH3>
                  <TypographyP className="!text-[14px] font-medium text-gray-500">
                    {feature.description}
                  </TypographyP>
                </div>
              ))}
            </CardContent>
            <CardFooter>
              {/* <div className="w-full" onClick={setUpCheckoutSession}> */}
              {/* <DialogCheckoutSession /> */}
              {/* </div> */}
              <Button
                borderRadius="1.75rem"
                className=" bg-bmpGreen-100 text-[17px] text-primary font-semibold dark:text-white border-neutral-200"
                containerClassName="w-full"
                onClick={() => {
                  setUpCheckoutSession(plan.id);
                }}
              >
                Start Subscription
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
      {panelPlans.map((p) => (
        <AllInOnePack plan={p} />
      ))}
    </section>
  );
};

export default PricingPlans;
