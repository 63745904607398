// import axiosInstance from "@/api/axios";
import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Lead,
  PROGRESS_BAR,
  Stage,
  STAGE_GROUP,
} from "./services/KanbanService";

type KANBAN = {
  stages: Stage[];
  leads: Lead[];
  lead_ids: number[];
  stage_group: STAGE_GROUP[];
  progress_bar: Record<number, PROGRESS_BAR> | null;
  leadsCOlor: LEAD_COLOR[];
};

export type LEAD_COLOR = {
  id: number;
  name: string;
  hex: string;
};

const initialState: KANBAN = {
  stages: [],
  leads: [],
  lead_ids: [],
  stage_group: [],
  progress_bar: null,
  leadsCOlor: [],
};

export const getStages = createAsyncThunk("kanban/getStages", async () => {
  const response = await axiosInstance({
    method: "GET",
    url: "/api/v1/stages",
  });
  console.log("response", response);
  // setColumns(response.data.data.data.stages);
  return response.data.data.data.stages;
});

const kanbanSlice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    setStages: (state, action) => {
      state.stages = action.payload;
    },
    setStageGroup: (state, action) => {
      state.stage_group = action.payload;
    },
    setLeads: (state, action) => {
      state.leads = action.payload;
    },
    setLeadsColor: (state, action) => {
      state.leadsCOlor = action.payload;
    },
    setProgressBar: (state, action) => {
      state.progress_bar = action.payload;
    },
    setLeadIds: (state, action) => {
      state.lead_ids = action.payload;
    },
  },
});

export const {
  setStages,
  setLeads,
  setProgressBar,
  setLeadIds,
  setStageGroup,
  setLeadsColor,
} = kanbanSlice.actions;
export default kanbanSlice.reducer;
