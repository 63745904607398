"use client";
import { useEffect, useState } from "react";
import { Sidebar, SidebarBody, SidebarLink } from "@/components/ui/sidebar";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logoBMP from "../../assets/logo.svg";
import favIcon from "../../../public/favicon.svg";
import TypographyH1 from "@/components/typography/TypographyH1";
import { PanelLeftClose, PanelLeftOpen } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { User } from "../auth/types";
import { logoutUser, setUser, toggleQuestion } from "../auth/UserSlice";
import dashboardIcon from "@/assets/sidenav/dashboard.svg";
import leadDataIcon from "@/assets/sidenav/lead-data.svg";
import crmIcon from "@/assets/sidenav/crm.svg";
import contactsIcon from "@/assets/sidenav/contacts.svg";
import quotesIcon from "@/assets/sidenav/quotes.svg";
import marketingIcon from "@/assets/sidenav/marketing-auto.svg";
import knowledgeBaseIcon from "@/assets/sidenav/knowledge-base.svg";
import signIcon from "@/assets/sidenav/sign.svg";
import invoicingIcon from "@/assets/sidenav/invoice.svg";
import helpdeskIcon from "@/assets/sidenav/helpdesk.svg";
import calendarIcon from "@/assets/sidenav/calendar.svg";
import allAppsIcon from "@/assets/apps/allApps.svg";
import integrationIcon from "@/assets/integration/integration.svg";
import discussIcon from "@/assets/sidenav/discuss.svg";
import { useLoading } from "@/contexts/LoadingContext";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { DialogDemo } from "@/components/dialogs/Dialog-ai";

const LINK_ICON_STYLE = "size-[25px] flex-shrink-0 hover:scale-125";
const links1 = [
  {
    label: "Analytics",
    href: "#",
    icon: (
      <img
        src={dashboardIcon}
        alt="dashboard icon"
        className={LINK_ICON_STYLE}
        title="Dashboard"
      />
    ),
  },
  {
    label: "Lead Data",
    href: "explore_leads",
    icon: (
      <img
        src={leadDataIcon}
        alt="lead data icon"
        className={LINK_ICON_STYLE}
        title="Lead Data"
      />
    ),
  },
  {
    label: "CRM",
    href: "/dashboard/CRM",
    icon: (
      <img
        src={crmIcon}
        alt="crm icon"
        className={LINK_ICON_STYLE}
        title="CRM"
      />
    ),
  },
  {
    label: "Calendar",
    href: "calendar",
    icon: (
      <img
        src={calendarIcon}
        alt="calendar icon"
        className={LINK_ICON_STYLE}
        title="Calendar"
      />
    ),
  },
  {
    label: "Contacts",
    href: "/dashboard/contacts",
    icon: (
      <img
        src={contactsIcon}
        alt="crm icon"
        className={LINK_ICON_STYLE}
        title="Contacts"
      />
    ),
  },

  {
    label: "Quotes",
    href: "/dashboard/quotes",
    icon: (
      <img
        src={quotesIcon}
        alt="quotes icon"
        className={LINK_ICON_STYLE}
        title="Quotes"
      />
    ),
  },
  {
    label: "Contracts",
    href: "contracts",
    icon: (
      <img
        src={signIcon}
        alt="contracts icon"
        title="Contracts"
        className={LINK_ICON_STYLE}
      />
    ),
  },
  {
    label: "Invoicing",
    href: "invoicing",
    icon: (
      <img
        src={invoicingIcon}
        alt="invoicing icon"
        className={LINK_ICON_STYLE}
        title="Invoicing"
      />
    ),
  },
  {
    label: "Automation",
    href: "automation",
    icon: (
      <img
        src={marketingIcon}
        alt="marketing icon"
        className={LINK_ICON_STYLE}
        title="Marketing"
      />
    ),
  },
  {
    label: "Integration",
    href: "/dashboard/integration",
    icon: (
      <img
        src={integrationIcon}
        alt="integration"
        className={LINK_ICON_STYLE}
        title="integration"
      />
    ),
  },
  {
    label: "Knowledge Base",
    href: "knowledge_base",
    icon: (
      <img
        src={knowledgeBaseIcon}
        alt="knowledge base icon"
        className={LINK_ICON_STYLE}
        title="Knowldge Base"
      />
    ),
  },
  {
    label: "All Apps",
    href: "/dashboard/all_apps",
    icon: (
      <img
        src={allAppsIcon}
        alt="all apps icon"
        className={LINK_ICON_STYLE}
        title="All Apps"
      />
    ),
  },
  {
    label: "Discuss",
    href: "/dashboard/discuss/inbox",
    icon: (
      <img
        src={discussIcon}
        alt="discuss icon"
        className={LINK_ICON_STYLE}
        title="Discuss"
      />
    ),
  },
  {
    label: "Helpdesk",
    href: "helpdesk",
    icon: (
      <img
        src={helpdeskIcon}
        alt="inventory icon"
        className={LINK_ICON_STYLE}
        title="Helpdesk"
      />
    ),
  },
];

export default function Layout() {
  return (
    // <SideNavProvider>
    <SidebarDemo />
    // </SideNavProvider>
  );
}

export function SidebarDemo() {
  const [isSidenavOpen, toggleSidenav] = useState<boolean>(true);

  const toggleOpen = () => {
    console.log("toggle open clicked", isSidenavOpen);
    toggleSidenav(!isSidenavOpen);
  };

  //   const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    console.log("side bar state changed", isSidenavOpen);
    // if (location.pathname.includes("explore/leads")) {
    //   dispatch(getProjectLeads());
    // }
  }, [isSidenavOpen]);

  const location = useLocation();

  return (
    <div
      className={cn(
        "rounded-md flex flex-col md:flex-row  flex-1 border border-neutral-200 dark:border-neutral-700 overflow-hidden",
        "h-screen" // for your use case, use `h-screen` instead of `h-[60vh]`
      )}
    >
      <Sidebar open={isSidenavOpen} setOpen={toggleSidenav} animate={true}>
        <SidebarHeader open={isSidenavOpen} />
        <SidebarBody className="relative justify-between gap-10 !bg-[#fbfbfb] border-r border-[#e4e4e4]">
          <Button
            type="button"
            size="icon"
            onClick={toggleOpen}
            className="cursor-pointer z-[50] !bg-[#f2f2f2] flex items-center justify-center absolute right-[-14px] size-[28px]"
          >
            {isSidenavOpen ? (
              <PanelLeftClose className="text-gray-500" size={20} />
            ) : (
              <PanelLeftOpen className="text-gray-500" size={20} />
            )}
          </Button>
          <div className="flex flex-col flex-1 overflow-y-auto mt-5 overflow-x-hidden">
            {/* {open ? <Logo /> : <LogoIcon />} */}
            {/* <Logo /> */}
            <div className="mt-8 flex flex-col gap-[10px]">
              {links1.map((link, idx) => (
                <SidebarLink
                  key={idx}
                  link={link}
                  className={`${location.pathname.includes(link.href) ? "bg-[#f2f2f2] opacity-100 rounded" : "opacity-75"} !no-underline p-2 text-[14px] text-[#727272] font-medium`}
                />
              ))}
            </div>
          </div>
        </SidebarBody>
        <section className="w-full flex flex-col mt-[55px] overflow-hidden bg-white">
          <ScrollArea className="h-full w-full ">
            <Outlet />
            <ScrollBar />
          </ScrollArea>
        </section>
      </Sidebar>
    </div>
  );
}

const SidebarHeader = ({ open }: { open: boolean }) => {
  const user = useSelector(
    (state: RootState) => state.rootReducer.userReducer.user
  );

  const [crmUser, setCRMUser] = useState<User | null>(user);

  const dispatch = useDispatch<AppDispatch>();
  const { setLoading } = useLoading();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("user", user);
    if (user) {
      //   const name = user.email?.split("@")[0];
      setCRMUser(user);
    } else {
      const localUser = JSON.parse(localStorage.getItem("user") + "");
      if (localUser) {
        console.log("local user", localUser);
        dispatch(setUser(localUser));
        const questionDone = localStorage.getItem("questionDone");
        if (questionDone) {
          dispatch(toggleQuestion(questionDone == "true"));
        }
      }
    }
  }, [user, dispatch]);

  console.log("user name", crmUser);

  const logout = () => {
    try {
      setLoading(true);
      dispatch(logoutUser()).then(() => {
        console.log("clicked");
        navigate(`/`);
        window.location.reload();
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute w-screen z-50 border-b pb-0 !bg-transparent h-[55px] flex items-center justify-between px-5">
      <div className="flex items-center w-[50%]">
        {open ? <Logo /> : <LogoIcon />}
        <TypographyH1
          className={`!text-[22px] ml-[50px] font-medium capitalize`}
        >
          {location.pathname.split("/")[2] != "404"
            ? location.pathname.split("/")[2].replace("_", " ")
            : "Coming Soon"}
        </TypographyH1>
      </div>
      <div className="flex items-center gap-1">
        <div className="">
          <DialogDemo />
        </div>
        <div className="border  h-[35px] w-[1px] mx-1"></div>
        {(location.pathname.includes("CRM") ||
          location.pathname.includes("leads/")) && (
          <Link to="/dashboard/explore_leads" className="!no-underline">
            <Button
              variant="ghost"
              className="text-bmpGreen-800 px-1"
              size="sm"
            >
              Switch to Explore Leads
            </Button>
          </Link>
        )}
        {location.pathname.includes("explore") && (
          <Link to="/dashboard/CRM" className="!no-underline">
            <Button
              variant="ghost"
              className="text-bmpGreen-800 px-1"
              size="sm"
            >
              Switch to CRM
            </Button>
          </Link>
        )}
        <div className="border  h-[35px] w-[1px] mx-1"></div>
        <DropdownMenu>
          <DropdownMenuTrigger className="border-none outline-none">
            <div
              // size="sm"
              // variant="outline"
              className="flex gap-2 items-center justify-center border rounded-md py-1 px-2"
            >
              {/* <CgProfile className="text-[20px]" /> */}
              <div>
                {crmUser?.user_avatar ? (
                  <div className="size-7 rounded-full">
                    <img
                      className="rounded-full"
                      src={`data:image/svg+xml;base64,${crmUser.user_avatar}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="size-7 rounded-full align-middle uppercase !bg-charcoal text-white flex items-center justify-center">
                    <span>
                      {crmUser?.email ? crmUser.email.substring(0, 2) : "-"}
                    </span>
                  </div>
                )}
              </div>
              <span className="capitalize">
                {crmUser?.email ? crmUser.email.split("@")[0] : "-"}
              </span>
              <GiHamburgerMenu className="text-[20px]" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-52 z-[1000] mr-5">
            <DropdownMenuLabel className="text-charcoal text-[16px]">
              My Account
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="text-charcoal text-[16px]">
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem className="text-charcoal text-[16px]">
              My Leads
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={logout}
              className="text-charcoal text-[16px]"
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export const Logo = () => {
  return (
    <Link
      to="/"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20"
    >
      {/* <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" /> */}
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="font-medium text-black dark:text-white whitespace-pre"
      >
        <img src={logoBMP} className="w-full" alt="bmp-logo" />
      </motion.span>
    </Link>
  );
};
export const LogoIcon = () => {
  return (
    <Link
      to="/"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20"
    >
      {/* <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" /> */}
      <img src={favIcon} className="w-8" alt="bmp-logo" />
    </Link>
  );
};
