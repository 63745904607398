import { lazy } from "react";

export const KanbanBoard = lazy(() => import("@/features/kanban/KanbanBoard"));

export const LeadDetails = lazy(() => import("@/pages/Leads/LeadDetails"));

export const Listing = lazy(() => import("@/features/project-listing/Layout"));

export const BlogsListing = lazy(
  () => import("@/features/blogs/list-blogs-layout")
);

export const BlogDetails = lazy(() => import("@/features/blogs/blog-page"));

export const ComingSoon = lazy(() => import("@/features/crm/coming-soon"));

export const Calendar = lazy(() => import("@/features/calendar/Calendar"));

export const AddLead = lazy(() => import("@/features/crm/addLeads/add-leads"));

export const ListView = lazy(
  () => import("@/features/kanban/views/listing-view")
);

export const ActivityView = lazy(
  () => import("@/features/kanban/views/activity-view")
);

export const Contacts = lazy(() => import("@/features/contacts/Layout"));

export const AllApps = lazy(() => import("@/features/all-apps/Layout"));

export const Integration = lazy(() => import("@/features/integration/Layout"));

export const Quotes = lazy(() => import("@/features/quotes/Layout"));

export const Invoicing = lazy(() => import("@/features/invoicing/Layout"));

export const Contracts = lazy(() => import("@/features/contracts/Layout"));

export const KnowledgeBase = lazy(
  () => import("@/features/knowledge-base/KnowledgeBase")
);

export const Helpdesk = lazy(() => import("@/features/helpdesk/Layout"));

export const DiscussLayout = lazy(() => import("@/features/discuss/Layout"));

export const Inbox = lazy(() => import("@/features/discuss/views/inbox/inbox"));

export const DirectMessage = lazy(
  () => import("@/features/discuss/views/direct-message/direct-message")
);

export const MarketingAutomation = lazy(
  () => import("@/features/marketing-automation/Layout")
);
export const PaymentSuccess = lazy(() => import("@/pages/payment-success"));
