import "./App.css";
import {
  useDispatch,
  //  useSelector
} from "react-redux";
import {
  AppDispatch,
  //  RootState
} from "@/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Header } from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import { ThemeProvider } from "@/contexts/ThemeProvider";
import { Outlet, useLocation } from "react-router-dom";
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingProvider } from "@/contexts/LoadingContext";
import Loader from "@/components/Loader";
import packagejson from "../package.json";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { TooltipProvider } from "./components/ui/tooltip";
import ErrorBoundary from "./contexts/ErrorBoundaryContext";
import ErrorPage from "./components/ErrorPage";
import "react-date-range/dist/styles.css"; // Main styles
import "react-date-range/dist/theme/default.css"; // Default theme
import { onMessageListener } from "./firebase";
import {
  // reLoginUser,
  setNotification,
} from "./features/auth/UserSlice";
import { SocketProvider } from "./contexts/SocketContext";

function App() {
  console.log("-------- app version --------", packagejson.version);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8fdda3", // Change primary color
      },
      secondary: {
        main: "#7dce93", // Change secondary color
      },
      // You can customize other colors as needed
      background: {
        default: "#f5f5f5", // Change default background color
      },
    },
    typography: {
      fontFamily: '"Outfit", "Arial", "sans-serif"',
      fontSize: 14,
    },
  });
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  // const user = useSelector(
  //   (root: RootState) => root.rootReducer.userReducer.user
  // );

  // Handle incoming messages
  onMessageListener()
    .then((payload) => {
      console.log("Message received. ", payload.notification);
      if (payload.notification) {
        dispatch(setNotification(payload.notification));
      }
      if (payload.notification?.title == "ReLogin") {
        // if (user) {
        //   dispatch(
        //     reLoginUser({
        //       platform: 3,
        //     })
        //   ).then(() => {
        //     window.location.reload();
        //   });
        // }
      } else alert(`New notification: ${payload.notification?.title}`);
    })
    .catch((err) => console.log("Failed to receive message: ", err));

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <MUIThemeProvider theme={theme}>
          <LoadingProvider>
            <TooltipProvider>
              <ErrorBoundary fallback={<ErrorPage />}>
                <SocketProvider>
                  <Loader />
                  <ScrollArea className="h-full">
                    {!location.pathname.includes("dashboard") && <Header />}
                    <Outlet />
                    {!location.pathname.includes("dashboard") && <Footer />}
                    <ToastContainer />
                    <ScrollBar />
                  </ScrollArea>
                </SocketProvider>
              </ErrorBoundary>
            </TooltipProvider>
          </LoadingProvider>
        </MUIThemeProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
