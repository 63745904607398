import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toastService from "@/services/toastService";
import { User } from "./types";
import { requestForToken } from "@/firebase";

// Initial state for the user slice
// Manages user authentication state, questionnaire completion status,
// country selection, and dialog visibility
const initialState = {
  user: null as User | null,
  questionDone: false,
  country: "CA",
  signUpDialog: false,
  loginDialog: false,
  paymentStatusDialog: false,
  notification: {} as object,
};

// Async thunk for handling user registration
// Makes API call to register new user and returns user data
export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (credentials: { email: string; password: string }) => {
    const response = await axiosInstance({
      method: "POST",
      url: "/user/api/v1/user/signup",
      data: credentials,
    });

    toastService.success(response.data.message);
    console.log(response);
    return response.data.data;
  }
);

// Async thunk for handling user login
// Makes API call to authenticate user and returns user data
export const signInUser = createAsyncThunk(
  "user/signUpUser",
  async (credentials: { email: string; password: string }) => {
    const response = await axiosInstance({
      method: "POST",
      url: "/user/api/v1/user/login",
      data: credentials,
    });

    console.log(response);
    return response.data.data;
  }
);

export const reLoginUser = createAsyncThunk(
  "user/reLoginUser",
  async (credentials: { platform: number }) => {
    const response = await axiosInstance({
      method: "POST",
      url: "/user/api/v1/user/reLoginWithToken",
      data: credentials,
    });
    return response.data.data;
  }
);

// Async thunk for handling user logout
// Makes API call to logout user and clear session
export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  const response = await axiosInstance({
    method: "POST",
    url: "/user/api/v1/user/logout",
  });

  console.log(response);
  return response.data.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Updates user state and handles token storage
    // Stores user data and access token in localStorage
    // Updates questionnaire completion status
    setUser: (state, action) => {
      state.user = action.payload;
      if (action.payload && action.payload?.accessToken) {
        state.questionDone = action.payload.questionnaireFilled;
        localStorage.setItem("user", JSON.stringify(action.payload));
        localStorage.setItem("accessToken", action.payload.accessToken);
        requestForToken(); // request notification token from the logged in user
      }
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    // Updates questionnaire completion status
    toggleQuestion: (state, action) => {
      state.questionDone = action.payload;
      localStorage.setItem("questionDone", action.payload);
    },
    // Updates selected country
    changeCountry: (state, action) => {
      console.log("change country called", action);
      state.country = action.payload;
    },
    // Controls signup dialog visibility
    // Only toggles if user is not logged in
    toggleSignUpDialog: (state, action) => {
      if (!state.user) {
        state.signUpDialog = action.payload;
      }
    },
    // Controls login dialog visibility
    // Only toggles if user is not logged in
    toggleloginDialog: (state, action) => {
      if (!state.user) {
        state.loginDialog = action.payload;
      }
    },
    togglePaymentStatusDialog: (state, action) => {
      console.log("toggle payment status dialog", action.payload);
      state.paymentStatusDialog = action.payload;
    },
  },
  // Extra reducers for handling async actions
  extraReducers: (builder) => {
    // On successful logout, clear localStorage and reset state
    builder.addCase(logoutUser.fulfilled, () => {
      localStorage.clear();
      return initialState;
    });
    builder.addCase(reLoginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      if (action.payload && action.payload?.accessToken) {
        state.questionDone = action.payload.questionnaireFilled;
        localStorage.setItem("user", JSON.stringify(action.payload));
        localStorage.setItem("accessToken", action.payload.accessToken);
        requestForToken(); // request notification token from the logged in user
      }
    });
  },
});

export const {
  setUser,
  toggleQuestion,
  changeCountry,
  toggleSignUpDialog,
  toggleloginDialog,
  setNotification,
  togglePaymentStatusDialog,
} = userSlice.actions;
export default userSlice.reducer;
